<template lang="pug">
.why
  .content
    h2 Por que a Magenta?

    .grid(ref="grid")

      .item
        h3.title A única personalizada de verdade
        .description
          | Ninguém gosta de receber produtos que não vai conseguir usar, não é mesmo?&#32;
          | É por isso que a gente toma o cuidado de entender quais produtos vão funcionar pra você e vão te agradar.
          | Até cor de base a gente acerta!
          br
          b Só a Magenta é assim, viu?!

      .item
        h3.title Só vem produtão, nada de amostrinha
        .description
          | A gente sabe que você quer aproveitar bastante do que vem na sua box, então, &#32;
          | diferente de outros clubes,&#32;
          b os 5 produtos que você recebe são em tamanho real
          | . Amostrinha ou travel-size, por aqui, só se for um extra!

      .item
        h3.title Se cuide muito mais, gastando muito menos
        .description
          b Os produtos que você recebe sempre valem muito mais do que o preço da sua assinatura. &#32;
          | Assim você pode ter certeza de que sua box vai sempre valer muito a pena

      .item
        h3.title Livre de crueldade animal
        .description
          b Todos os produtos enviados são livres de crueldade animal.&#32;
          | A gente faz questão de garantir isso!

      .item
        h3.title Troque pontos por produtos
        .description
          b Acumule pontos durante a sua assinatura e troque por produtos &#32;
          | para deixar a sua box ainda mais especial e com a sua cara!

      //- .item
      //-   h3.title Entrega rápida
      //-   .description
      //-     | Depois da confirmação de pagamento,&#32;
      //-     b enviamos sua box em até 5 dias úteis.&#32;
      //-     | Por aqui ninguém te deixa te esperando por um tempão

</template>
<script setup lang="ts">
const grid = ref<HTMLElement>()

var intersectionObserver: IntersectionObserver | null = null
onMounted(() => {
  if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window) {
    intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !entry.target.classList.contains("visible")) {
          entry.target.classList.add("visible")
        }
      })
    }, { threshold: 0.5 })

    for (var i = 0; i < grid.value!.children.length; i++) {
      intersectionObserver.observe(grid.value!.children[i]);
    }
  } else {
    // Fallback
    for (var i = 0; i < grid.value!.children.length; i++) {
      grid.value!.children[i].classList.add("visible");
    }
  }
})

onBeforeUnmount(() => {
  intersectionObserver?.disconnect()
})
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.why
  position: relative
  background-color: #1E1E1E
  color: white

  > .content
    position: relative
    max-width: 1232px
    margin: 0 auto
    padding: 56px 16px

    > h2
      text-align: center
      margin: 0 0 24px
      color: white

    > .grid
      display: grid
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
      gap: 16px

      > .item
        transform: translateY(32px)
        opacity: 0
        transition: transform .3s ease-out .16s, opacity .3s ease-out .16s

        &.visible
          transform: translateY(0)
          opacity: 1

        > h3
          color: white

        > .description
          text-align: left
          font-size: 14px

    @include breakpoint(mobileonly)

      > .grid
        grid-template-columns: repeat(2, 1fr)
        gap: 32px

        > .item

          > .description
            font-size: 16px
</style>
