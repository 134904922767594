<template lang="pug">
#oferta.offer

  .content
    h2 Escolha o seu plano com desconto!

    .offer-plans

      NuxtLink#plano-anual.offer-plan(
        :to="offerPath('annually')"
      )

        .offer-plan--header
          span.offer-plan--title
            | Plano Anual

        .magenta-friday
          .melhor-opcao
            img(src="~/assets/home/promotions/2024-11-black-friday/melhor-opcao.svg")

          Logo

        .offer-plan--pricing

          .regular-price R$ 149,90

          .price
            span.magenta.small R$
            span.magenta.bold.large 99,90

          .detail na sua 1ª box

        .offer-plan--description Cobrança anual parcelada

        .offer-plan--cta Quero aproveitar

      NuxtLink#plano-semestral.offer-plan(
        :to="offerPath('biannually')"
      )
        .offer-plan--header
          span.offer-plan--title
            | Plano Semestral

        .magenta-friday
          Logo

        .offer-plan--pricing

          .regular-price R$ 159,90

          .price
            span.magenta.small R$
            span.magenta.bold.large 109,90

          .detail na sua 1ª box

        .offer-plan--description
          | Cobrança semestral parcelada

        .offer-plan--cta Quero aproveitar

      NuxtLink#plano-mensal.offer-plan(
        :to="offerPath('monthly')"
      )
        .offer-plan--header
          span.offer-plan--title
            | Plano Mensal

        .magenta-friday
          Logo


        .offer-plan--pricing

          .regular-price R$ 169,90

          .price
            span.magenta.small R$
            span.magenta.bold.large 129,90

          .detail na sua 1ª box

        .offer-plan--description
          | Cancele quando quiser, sem multa

        .offer-plan--cta Quero aproveitar

    span.notice Renovação automática em todos os planos

    ClientOnly
      SimpleSubscriptionGiftPopup
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { SubscriptionGiftAvailabilityResponse } from "~/services/subscription/promotion/promotionService"

import { planAliasToId } from "~/services/subscription/subscriptionService"
import { useAuth } from "~/store/auth"

import Logo from "./MagentaFridayLogo.vue"

const props = defineProps({
  subscriptionGiftPromotions: {
    type: Object as PropType<SubscriptionGiftAvailabilityResponse[]>,
    required: true,
    default: []
  }
})

const auth = useAuth()
const route = useRoute()

const offerPath = (plan: string) => {
  const promotion = subscriptionGiftPromotionForPlan(plan)?.ruleId
  const subscriptionGiftId = promotion ?? undefined
  const coupon = route.query.coupon ?? undefined

  const query = {
    plan,
    subscriptionGiftId,
    coupon
  }

  if (auth.isAuthenticated) {
    return { name: 'subscribe', query }
  } else {
    return { name: 'subscription-register', query }
  }
}

const subscriptionGiftPromotionForPlan = (plan: string) => {
  const planId = planAliasToId(plan);
  const promotion = props.subscriptionGiftPromotions.find((promotion) =>
    promotion.planIds.indexOf(planId) >= 0
  );

  return promotion ?? null;
};

const monthlyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("monthly"));
const biannuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("biannually"));
const annuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("annually"));
</script>

<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.offer
  background-color: #1E1E1E
  color: white
  padding: 56px 48px

  .content
    display: flex
    width: 100%
    max-width: 1440px
    margin: 0 auto
    flex-direction: column
    justify-content: center
    align-items: center

    h2
      position: relative
      font-size: 32px
      margin: 0 0 24px
      color: white
      text-align: center

    .offer-plans
      display: flex
      width: 100%
      flex-direction: row
      justify-content: center
      flex-wrap: wrap
      align-content: center
      gap: 32px

      .offer-plan
        position: relative
        display: flex
        flex-direction: column
        align-items: stretch
        gap: 24px
        flex: 1
        min-width: 302px
        box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.2)
        border-radius: 36px
        padding: 68px 42px
        color: $beige
        text-align: center
        justify-content: space-between
        align-items: center
        font-family: "Spartan", sans-serif
        background-color: transparent
        border: 1px solid #FFEDE0

        &#plano-anual
          background-color: #FFEDE0 !important
          color: #1E1E1E

          .offer-plan--pricing

            .regular-price
              color: #1E1E1E

              &::after
                background-color: #1E1E1E

            .detail
              color: #1E1E1E

        .offer-plan--header
          position: relative
          color: $magenta

          span.offer-plan--title
            font-size: 20px
            font-weight: 600
            margin: 0
            text-transform: uppercase
            flex-grow: 1

        .offer-plan--pricing
          display: flex
          flex-direction: column
          gap: 12px

          span.small
            font-size: 32px

          span.large
            font-size: 54px
            line-height: 38px

          span.magenta
            color: $magenta

          .regular-price
            display: inline-block
            position: relative
            font-size: 22px
            line-height: 32px

            &:after
              display: block
              position: absolute
              content: ''
              left: 50%
              top: 50%
              width: 128px
              height: 2px
              background-color: $beige
              transform: translate(-50%, -50%) rotate(-3deg)

          .detail
            font-size: 18px
            color: $beige

        span.bold
          font-weight: 800

        span.strikethrough
          text-decoration: line-through

          br.installments-line-break
            display: none

        .offer-plan--gift
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          font-size: 12px
          max-width: 240px
          background-color: rgba(0, 0, 0, 0.2)
          padding: 8px 12px
          border-radius: 4px
          gap: 4px
          white-space: pre-line

          .icon
            display: flex
            justify-content: center
            align-items: center
            height: 32px
            width: 32px
            font-size: 16px
            flex-shrink: 0

        .offer-plan--description
          font-size: 18px

        .offer-plan--cta
          position: relative
          display: inline-flex
          padding: 12px 24px
          align-self: center
          font-size: 20px
          font-weight: 600
          line-height: normal
          background-color: $magenta
          border-radius: 32px
          color: $beige

        &.biannually
          padding-bottom: 18px

          .offer-plan--header, .offer-plan--pricing
            text-align: left

          .offer-plan--cta
            position: absolute
            bottom: 16px

    span.notice
      margin-top: 32px
      font-size: 20px
      text-align: center
      color: $beige

  @include upperBreakpoint(mobileonly)
    .offer
      .offer-plans
        max-height: 100%

        .offer-plan
          .offer-plan--header
            span.offer-plan--title
              font-size: 28px

          .offer-plan--pricing
            font-size: 18px

            span.small
              font-size: 14px

            span.large
              font-size: 28px

  @include upperBreakpoint(extrasmall)
    .offer
      .offer-plans
        .offer-plan
          .offer-plan--header
            span.offer-plan--title
              font-size: 18px

@include tablet
  .offer

    .content

      .offer-plans
        flex-direction: column

@include tablet
  .offer
    padding: 56px 32px

    .content

      .offer-plans
        flex-direction: column

        .offer-plan
          gap: 16px
          padding: 24px 16px

          .offer-plan--header

            span.offer-plan--title
              font-size: 16px

          .magenta-friday
            .melhor-opcao
              top: 0
              left: 0
              transform: translate(-30%, -30%)

              img
                width: 96px

            svg
              display: none

          .offer-plan--pricing
            gap: 8px

            .regular-price
              font-size: 16px
              line-height: 24px

              &:after
                width: 96px

            span.small
              font-size: 20px

            span.large
              font-size: 38px

            .detail
              font-size: 14px

          .offer-plan--description
            font-size: 12px
</style>
<style lang="sass">
@import "~/assets/styles/variables"

.offer-plan
  &#plano-anual

    svg
      path
        fill: #1E1E1E

  .magenta-friday
    .melhor-opcao
      position: absolute
      top: 0
      left: 0
      transform: translate(-30%, -30%)

    svg
      path
        fill: $beige
</style>
