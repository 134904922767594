<template lang="pug">
.brands

  .content

    h2 O que vem na Box Magenta?
    .subtitle
      | Toda box conta com 5 produtos em tamanho real das melhores marcas do mercado.
      br
      | Dá uma olhada em algumas delas:

    .brands-carousel
      ClientOnly
        Carousel(
          ref="carousel",
          :settings="carouselSettings",
          :breakpoints="carouselBreakpoints",
          :wrap-around="true",
          :autoplay="autoplay",
          :pause-autoplay-on-hover="true"
        )
          Slide(key="the-body-shop")
            picture
              source(
                srcset="~/assets/home/sections/brands/the-body-shop.webp 1x, ~/assets/home/sections/brands/the-body-shop-2x.webp 2x, ~/assets/home/sections/brands/the-body-shop-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/the-body-shop.webp 1x, ~/assets/home/sections/brands/the-body-shop-2x.webp 2x, ~/assets/home/sections/brands/the-body-shop-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/the-body-shop.webp",
                height="480",
                width="480"
              )

            .brand-title
              | The Body Shop

          Slide(key="loccitane-au-bresil")
            picture
              source(
                srcset="~/assets/home/sections/brands/loccitane-au-bresil.webp 1x, ~/assets/home/sections/brands/loccitane-au-bresil-2x.webp 2x, ~/assets/home/sections/brands/loccitane-au-bresil-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/loccitane-au-bresil.webp 1x, ~/assets/home/sections/brands/loccitane-au-bresil-2x.webp 2x, ~/assets/home/sections/brands/loccitane-au-bresil-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/loccitane-au-bresil.webp",
                height="480",
                width="480"
              )

            .brand-title
              | L'Occitane au Brésil

          Slide(key="sallve")
            picture
              source(
                srcset="~/assets/home/sections/brands/sallve.webp 1x, ~/assets/home/sections/brands/sallve-2x.webp 2x, ~/assets/home/sections/brands/sallve-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/sallve.webp 1x, ~/assets/home/sections/brands/sallve-2x.webp 2x, ~/assets/home/sections/brands/sallve-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/sallve.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Sallve

          Slide(key="brae")
            picture
              source(
                srcset="~/assets/home/sections/brands/brae.webp 1x, ~/assets/home/sections/brands/brae-2x.webp 2x, ~/assets/home/sections/brands/brae-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/brae.webp 1x, ~/assets/home/sections/brands/brae-2x.webp 2x, ~/assets/home/sections/brands/brae-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/brae.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Braé

          Slide(key="natura")
            picture
              source(
                srcset="~/assets/home/sections/brands/natura.webp 1x, ~/assets/home/sections/brands/natura-2x.webp 2x, ~/assets/home/sections/brands/natura-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/natura.webp 1x, ~/assets/home/sections/brands/natura-2x.webp 2x, ~/assets/home/sections/brands/natura-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/natura.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Natura

          Slide(key="linha-bruna-tavares")
            picture
              source(
                srcset="~/assets/home/sections/brands/linha-bruna-tavares.webp 1x, ~/assets/home/sections/brands/linha-bruna-tavares-2x.webp 2x, ~/assets/home/sections/brands/linha-bruna-tavares-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/linha-bruna-tavares.webp 1x, ~/assets/home/sections/brands/linha-bruna-tavares-2x.webp 2x, ~/assets/home/sections/brands/linha-bruna-tavares-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/linha-bruna-tavares.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Linha Bruna Tavares

          Slide(key="vizzela")
            picture
              source(
                srcset="~/assets/home/sections/brands/vizzela.webp 1x, ~/assets/home/sections/brands/vizzela-2x.webp 2x, ~/assets/home/sections/brands/vizzela-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/vizzela.webp 1x, ~/assets/home/sections/brands/vizzela-2x.webp 2x, ~/assets/home/sections/brands/vizzela-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/vizzela.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Vizzela

          Slide(key="flo")
            picture
              source(
                srcset="~/assets/home/sections/brands/flo.webp 1x, ~/assets/home/sections/brands/flo-2x.webp 2x, ~/assets/home/sections/brands/flo-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/flo.webp 1x, ~/assets/home/sections/brands/flo-2x.webp 2x, ~/assets/home/sections/brands/flo-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/flo.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Flô

          Slide(key="alchi")
            picture
              source(
                srcset="~/assets/home/sections/brands/alchi.webp 1x, ~/assets/home/sections/brands/alchi-2x.webp 2x, ~/assets/home/sections/brands/alchi-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/alchi.webp 1x, ~/assets/home/sections/brands/alchi-2x.webp 2x, ~/assets/home/sections/brands/alchi-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/alchi.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Alchi

          Slide(key="bergamia")
            picture
              source(
                srcset="~/assets/home/sections/brands/bergamia.webp 1x, ~/assets/home/sections/brands/bergamia-2x.webp 2x, ~/assets/home/sections/brands/bergamia-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/bergamia.webp 1x, ~/assets/home/sections/brands/bergamia-2x.webp 2x, ~/assets/home/sections/brands/bergamia-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/bergamia.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Bergamía

          Slide(key="tracta")
            picture
              source(
                srcset="~/assets/home/sections/brands/tracta.webp 1x, ~/assets/home/sections/brands/tracta-2x.webp 2x, ~/assets/home/sections/brands/tracta-3x.webp 3x",
                type="image/webp"
              )
              source(
                srcset="~/assets/home/sections/brands/tracta.webp 1x, ~/assets/home/sections/brands/tracta-2x.webp 2x, ~/assets/home/sections/brands/tracta-3x.webp 3x"
              )
              img(
                src="~/assets/home/sections/brands/tracta.webp",
                height="480",
                width="480"
              )

            .brand-title
              | Tracta

          template(#addons)
            Pagination(ref="pagination")
</template>
<script setup lang="ts">
import { Carousel, Slide, Pagination } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

const carousel = ref(null)
const pagination = ref(null)

const carouselVisible = useElementVisibility(carousel)
const paginationVisible = useElementVisibility(pagination)

const carouselSettings = {
  itemsToShow: 1
}

const carouselBreakpoints = {
  480: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 4,
    snapAlign: 'start',
  }
}

const autoplay = computed<number | undefined>(() => {
  if (paginationVisible.value) {
    return 3000
  }

  return undefined
})
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.brands
  background-color: #3a3a3a
  color: white

  .content
    position: relative
    max-width: 1482px
    width: 100%
    margin: 0 auto
    overflow: hidden
    padding: 56px 16px

    h2
      margin: 0 0 16px
      text-align: center
      color: white

    > .subtitle
      font-size: 16px
      margin: 0 0 24px

    picture
      display: flex

    img
      width: 100%
      height: auto
      aspect-ratio: 1
      object-fit: contain

    .brand-title
      position: absolute
      display: flex
      right: 16px
      top: 16px
      background-color: $magenta
      white-space: nowrap
      padding: 8px
      height: 28px
      border-radius: 14px
      color: white
      font-size: 14px
      line-height: 14px
      font-weight: bold

</style>
<style lang="sass">
@import '~/assets/styles/variables'

.brands

  .content
    text-align: center

    .carousel__viewport
      border-radius: 8px

    .carousel__slide--visible
      transform: rotateY(0)

    .carousel__pagination
      margin: 8px 0
      padding: 0

      .carousel__pagination-button

        &:after
          width: 14px
          height: 5px
          border-radius: 5px
          background-color: transparent
          border: 1px solid $magenta
          box-sizing: border-box

      .carousel__pagination-button--active

        &:after
          background-color: $magenta
</style>
