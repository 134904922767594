<template>
<svg xmlns="http://www.w3.org/2000/svg" width="216" height="74" viewBox="0 0 216 74" fill="none">
  <path d="M32.7887 22.7846C32.9557 26.7321 35.7805 25.485 35.7805 28.0219C35.7805 29.0204 35.1978 29.3508 33.3714 29.3508H26.059C24.3144 29.3508 23.6499 29.0168 23.6499 28.0219C23.6499 25.6946 26.1016 26.5261 26.059 22.7846L25.8103 8.57186L17.5385 26.3591C17.1654 27.1479 16.7923 27.4819 15.8365 27.6879C14.8807 27.894 14.3406 27.5209 14.0919 26.8991L6.44191 8.82059L5.98355 24.0317C5.90182 26.6504 8.06215 26.6504 8.06215 28.1463C8.06215 29.1021 7.44035 29.3082 6.15055 29.3082H2.03597C0.497445 29.3082 0 29.0168 0 28.1037C0 26.7321 2.36997 26.6504 2.49433 24.0317L3.36841 6.03845C3.49277 3.04668 0.874081 3.54412 0.874081 1.59343C0.874081 0.594985 1.49589 0.260986 3.03441 0.260986H8.60224C9.47632 0.260986 10.0555 0.63407 10.4321 1.42287L17.6202 18.0446L25.391 1.46551C25.8067 0.63407 26.3468 0.260986 27.2209 0.260986H32.4973C34.0359 0.260986 34.7003 0.594985 34.7003 1.58987C34.7003 3.54412 32.0816 3.00404 32.206 6.0349L32.7887 22.781V22.7846Z" fill="#1E1E1E"/>
  <path d="M60.301 26.6504C60.301 28.228 57.6823 29.6848 55.6463 29.6848C53.859 29.6848 52.5302 28.8107 51.7804 26.693C49.6627 28.8534 46.8771 29.6848 44.3827 29.6848C39.9768 29.6848 36.9424 27.1514 36.9424 23.7013C36.9424 19.7537 40.3925 16.801 46.7065 16.801C47.4562 16.801 48.1207 16.8827 49.4496 17.0071C48.7425 14.7615 47.744 12.8499 44.9193 12.8499C43.299 12.8499 41.5118 13.6387 40.1402 14.3884C39.3514 14.8858 38.353 14.264 39.0174 13.0986C40.0976 11.2296 43.3381 8.98401 47.41 8.98401C53.1022 8.98401 54.559 13.0133 55.5539 16.3817L57.6716 23.6124C58.0873 25.0657 58.5457 25.8971 59.5406 25.858C59.9989 25.8154 60.2903 26.1494 60.2903 26.6468L60.301 26.6504ZM50.949 23.4099C50.8673 22.8272 50.4906 21.7897 50.0749 20.5425C49.7409 19.5867 48.7034 19.171 47.3319 19.171C44.7132 19.171 43.0929 20.5425 43.0929 22.5785C43.0929 24.7814 44.7558 26.4017 47.2501 26.4017C49.3287 26.4017 51.2794 25.0301 50.949 23.4099Z" fill="#1E1E1E"/>
  <path d="M83.9082 30.5554C83.9082 35.1674 78.7561 38.0775 71.6498 38.0775C67.2865 38.0775 62.1344 36.7059 62.1344 33.1741C62.1344 31.8843 63.0902 30.598 64.6678 29.9727C63.0902 29.2229 62.0917 27.9793 62.0917 26.3165C62.0917 24.6536 63.0902 23.3247 65.2931 22.2445C63.5059 21.1643 62.4257 19.4197 62.4257 17.1741C62.4257 12.8926 66.622 9.15462 72.2751 8.98762H73.604C74.3928 9.03026 74.8512 9.11198 75.5583 9.11198C76.4714 9.11198 77.8856 8.82062 79.5911 6.95165C80.0886 6.32984 80.6713 5.91412 81.5027 5.91412C82.5012 5.91412 83.3326 6.70293 83.3326 7.78309C83.3326 9.81906 81.087 10.7322 79.0937 10.9845C80.5078 12.189 81.3393 13.8946 81.3393 15.9732C81.3393 20.3791 77.4343 23.577 71.8239 23.744C70.2854 23.7866 68.8747 23.6196 67.5849 23.2465C67.1692 23.5805 66.9205 24.0353 66.9205 24.4937C66.9205 25.6556 68.1677 25.9896 69.994 25.9896C72.364 25.9896 76.1019 25.5312 78.0561 25.5312C81.8367 25.5312 83.9153 27.6098 83.9153 30.5589L83.9082 30.5554ZM78.2125 32.634C78.2125 31.7599 77.6298 31.0955 76.1339 31.1381C73.6005 31.1381 71.8132 31.4295 69.3189 31.3442C68.2814 31.3016 67.2403 31.6782 67.2403 32.8827C67.2403 34.503 69.4006 35.2918 72.8081 35.2918C76.7557 35.2918 78.2125 34.169 78.2125 32.6304V32.634ZM68.6544 15.2199C68.6544 18.2969 69.8163 21.2887 72.4777 21.2887C74.0162 21.2887 75.0964 19.9172 75.0964 17.5081C75.0964 14.8894 73.9309 11.5246 71.3549 11.5246C69.7346 11.5246 68.6544 13.1022 68.6544 15.2234V15.2199Z" fill="#1E1E1E"/>
  <path d="M104.73 23.6978C103.941 26.1494 100.367 29.7666 94.6743 29.7666C88.9821 29.7666 84.7006 25.7373 84.7006 19.9598C84.7006 13.5179 89.9379 8.98761 95.5484 8.98761C100.786 8.98761 103.568 12.189 104.069 15.7209C104.151 16.3427 103.82 16.968 103.113 17.2985C98.9985 19.2918 96.1311 20.4999 92.0592 22.0775C92.9333 23.6978 94.6352 25.151 97.5453 25.151C99.9543 25.151 101.866 23.9038 103.365 22.4897C104.279 21.6156 105.153 22.408 104.737 23.6942L104.73 23.6978ZM90.7232 16.7193C90.7232 17.5934 90.8902 18.7126 91.0963 19.3806C93.0079 18.7162 94.9621 18.0091 96.7885 17.135C97.4955 16.801 98.2026 16.5523 97.826 15.3052C97.1615 12.9778 95.3317 11.6063 93.4627 12.1037C91.7181 12.5195 90.7196 14.2641 90.7196 16.7158L90.7232 16.7193Z" fill="#1E1E1E"/>
  <path d="M130.618 24.4474C131.158 26.9808 132.779 26.6078 132.779 28.1889C132.779 29.1447 132.029 29.3508 130.867 29.3508H124.343C123.096 29.3508 122.35 29.0594 122.35 28.1463C122.35 26.693 124.553 26.7321 123.97 24.0317L122.641 17.6324C122.144 15.3477 121.312 13.1021 119.067 13.1448C117.113 13.1448 115.578 14.932 115.578 17.8421V23.4525C115.578 27.2331 117.614 26.1956 117.614 28.1889C117.614 29.1447 116.907 29.3508 115.702 29.3508H108.969C107.722 29.3508 106.975 29.0594 106.975 28.1463C106.975 26.192 109.054 27.2331 109.054 23.449V15.7599C109.054 14.7224 108.432 14.097 107.764 13.5143C107.391 13.2229 106.975 12.8925 106.975 12.2245C106.975 11.4783 107.349 11.1443 108.347 10.686C109.967 9.93981 113.126 9.02309 113.833 8.98401C114.583 8.98401 114.871 9.48145 114.871 10.3982V12.4341C116.409 10.1494 118.776 8.98401 121.437 8.98401C126.465 8.98401 128.046 12.4341 129.126 17.4619L130.622 24.4439L130.618 24.4474Z" fill="#1E1E1E"/>
  <path d="M148.409 25.1545C147.745 27.2331 145.46 29.7666 141.761 29.7666C137.856 29.7666 135.404 27.3575 135.404 22.8663V20.2476C135.404 17.1315 135.447 14.3031 135.529 12.3525H134.616C133.368 12.3525 133.034 11.9367 133.034 11.1906C133.034 10.5688 133.283 9.9434 133.784 9.48504C135.323 7.98916 137.565 6.20191 139.437 5.16438C140.517 4.54258 140.933 4.49994 141.182 4.49994C141.765 4.49994 142.219 5.12174 142.18 5.7471C142.056 6.95163 142.013 8.15616 141.974 9.40332H146.089C146.878 9.40332 147.585 9.69468 147.336 11.0662C147.087 12.4377 146.338 12.6865 145.424 12.6438L141.935 12.5195V20.2476C141.892 24.4048 142.557 25.318 144.22 25.318C145.257 25.318 146.213 24.8596 147.13 24.1952C148.004 23.6125 148.707 24.2378 148.42 25.151L148.409 25.1545Z" fill="#1E1E1E"/>
  <path d="M173.633 26.6504C173.633 28.228 171.014 29.6848 168.978 29.6848C167.191 29.6848 165.862 28.8107 165.112 26.693C162.995 28.8534 160.209 29.6848 157.715 29.6848C153.309 29.6848 150.274 27.1514 150.274 23.7013C150.274 19.7537 153.725 16.801 160.042 16.801C160.792 16.801 161.456 16.8827 162.785 17.0071C162.078 14.7615 161.08 12.8499 158.255 12.8499C156.635 12.8499 154.847 13.6387 153.476 14.3884C152.687 14.8858 151.689 14.264 152.353 13.0986C153.433 11.2296 156.674 8.98401 160.749 8.98401C166.441 8.98401 167.898 13.0133 168.893 16.3817L171.011 23.6124C171.426 25.0657 171.885 25.8971 172.88 25.858C173.338 25.8154 173.629 26.1494 173.629 26.6468L173.633 26.6504ZM164.281 23.4099C164.199 22.8272 163.823 21.7897 163.407 20.5425C163.073 19.5867 162.035 19.171 160.664 19.171C158.045 19.171 156.425 20.5425 156.425 22.5785C156.425 24.7814 158.088 26.4017 160.582 26.4017C162.661 26.4017 164.615 25.0301 164.281 23.4099Z" fill="#1E1E1E"/>
  <path d="M110.625 43.5067C109.793 43.798 109.21 43.464 108.507 42.3839C106.762 39.7652 105.014 38.4363 102.399 38.4363H100.53C97.9113 38.4363 97.3286 39.019 97.3286 40.1809V44.6259C97.3286 46.2888 98.2417 46.6619 99.9473 46.6619H100.569C103.643 46.6619 103.561 45.2087 105.099 45.2087C106.013 45.2087 106.265 45.7914 106.428 46.4132L107.551 51.7323C107.842 53.1855 107.551 53.5622 106.677 53.7683C104.474 54.2657 104.225 49.611 100.569 49.611H99.9473C98.2417 49.611 97.3286 49.945 97.3286 51.647V57.1331C97.3286 62.2035 100.114 60.9563 100.114 63.2019C100.114 64.2004 99.5315 64.5308 97.6626 64.5308H90.0588C88.2715 64.5308 87.6071 64.1968 87.6071 63.2019C87.6071 60.9563 90.4745 62.2035 90.4745 57.1331V43.1691C90.4745 37.4343 87.6071 39.2642 87.6071 36.7699C87.6071 35.7714 88.2715 35.441 90.0588 35.441H106.517C108.969 35.441 109.718 35.6897 110.216 36.9795C110.589 38.1023 111.005 39.5129 111.296 41.2184C111.545 42.4656 111.42 43.2544 110.632 43.5031L110.625 43.5067Z" fill="#1E1E1E"/>
  <path d="M129.201 48.1223C129.201 50.0339 128.245 51.1958 126.998 51.1958C124.795 51.1958 124.919 48.8684 122.84 48.8684C121.512 48.8684 120.346 50.1973 120.346 52.9013V58.6361C120.346 62.4167 122.382 61.3792 122.382 63.3725C122.382 64.3283 121.675 64.5344 120.47 64.5344H113.737C112.49 64.5344 111.744 64.243 111.744 63.3299C111.744 61.3756 113.822 62.4167 113.822 58.6326V50.9435C113.822 49.906 113.201 49.2806 112.533 48.6979C112.16 48.4065 111.744 48.0761 111.744 47.4116C111.744 46.6619 112.117 46.3315 113.115 45.8731C114.736 45.1269 117.894 44.2102 118.601 44.1711C119.348 44.1711 119.639 44.6686 119.639 45.5853V47.6213C121.302 45.1269 123.462 44.1711 125.292 44.1711C127.744 44.1711 129.197 45.7914 129.197 48.1187L129.201 48.1223Z" fill="#1E1E1E"/>
  <path d="M133.525 51.3201C133.525 48.8258 131.571 48.911 131.571 47.6639C131.571 47.0421 131.944 46.7081 132.942 46.2497C134.605 45.4609 138.386 44.2137 139.093 44.2137C139.842 44.2137 140.13 44.7112 140.13 45.6279L140.088 51.3201V58.6325C140.088 62.4131 142.123 61.3756 142.123 63.3689C142.123 64.3247 141.416 64.5308 140.212 64.5308H133.521C132.231 64.5308 131.485 64.2394 131.485 63.3263C131.485 61.372 133.564 62.4131 133.564 58.629L133.521 51.3165L133.525 51.3201ZM132.651 39.5591C132.651 37.3988 135.021 35.1141 137.387 35.1531C139.05 35.1531 140.379 36.3577 140.379 37.9779C140.336 40.3052 137.885 42.2986 135.475 42.2986C133.646 42.2986 132.608 41.0088 132.651 39.5555V39.5591Z" fill="#1E1E1E"/>
  <path d="M165.358 42.1352L165.276 57.8864C165.276 60.8782 167.685 60.3381 167.685 61.5852C167.685 62.2497 167.227 62.6654 166.189 63.2481C164.86 63.9126 162.156 64.8257 160.913 64.8257C159.669 64.8257 159.125 64.1613 159.001 62.2497V61.8766C157.672 63.6639 155.636 65.0354 152.477 64.911C147.904 64.7867 144.458 61.2548 144.458 55.8504C144.458 48.2857 150.441 44.1711 155.053 44.1711C156.549 44.1711 157.797 44.4625 158.795 44.9173V42.1316C158.795 39.6799 156.841 39.7226 156.841 38.4754C156.841 37.8536 157.214 37.5196 158.212 37.0612C159.875 36.2724 163.656 35.0253 164.363 35.0253C165.112 35.0253 165.4 35.5227 165.4 36.4394L165.358 42.1316V42.1352ZM158.791 56.5966V53.8962C158.71 52.1516 158.457 50.6557 158.084 49.739C157.338 47.9517 156.048 47.0776 154.51 47.0776C152.058 47.0776 150.644 49.3232 150.644 53.0221C150.644 57.6768 152.68 60.8355 155.423 60.8355C157.502 60.8355 158.582 59.0909 158.788 56.5966H158.791Z" fill="#1E1E1E"/>
  <path d="M192.785 61.834C192.785 63.4116 190.166 64.8684 188.13 64.8684C186.343 64.8684 185.014 63.9943 184.264 61.8766C182.146 64.0369 179.361 64.8684 176.866 64.8684C172.46 64.8684 169.426 62.335 169.426 58.8848C169.426 54.9373 172.876 51.9846 179.194 51.9846C179.943 51.9846 180.608 52.0663 181.937 52.1907C181.23 49.945 180.235 48.0334 177.406 48.0334C175.786 48.0334 173.999 48.8222 172.627 49.572C171.839 50.0694 170.84 49.4476 171.505 48.2857C172.585 46.4167 175.825 44.1711 179.901 44.1711C185.593 44.1711 187.05 48.2004 188.045 51.5689L190.162 58.7996C190.578 60.2528 191.036 61.0843 192.031 61.0452C192.49 61.0025 192.781 61.3365 192.781 61.834H192.785ZM183.436 58.5935C183.355 58.0108 182.978 56.9732 182.562 55.7261C182.228 54.7703 181.191 54.3545 179.819 54.3545C177.2 54.3545 175.58 55.7261 175.58 57.762C175.58 59.965 177.243 61.5853 179.737 61.5853C181.816 61.5853 183.77 60.2137 183.436 58.5935Z" fill="#1E1E1E"/>
  <path d="M213.233 44.5869C214.523 44.5869 215.269 44.8782 215.269 45.7487C215.269 47.5786 213.439 46.7863 212.11 50.2364L206.958 63.5359C204.588 69.6048 202.552 73.261 197.109 73.261C194.533 73.261 191.623 71.8894 191.623 68.9794C191.623 67.3591 192.66 65.7389 194.572 65.7389C197.358 65.7389 196.899 70.1022 199.308 70.1022C200.555 70.1022 201.554 68.688 201.554 66.8191C201.554 66.0303 201.348 65.1988 201.014 64.2857L195.403 50.24C194.074 46.7898 192.245 47.5786 192.245 45.7523C192.245 44.7965 192.994 44.5904 194.156 44.5904H201.305C202.591 44.5904 203.341 44.8818 203.341 45.7523C203.341 47.5822 201.014 46.7898 202.343 50.24L205.793 59.0909L209.076 50.24C210.405 46.7898 208.12 47.5786 208.12 45.7523C208.12 44.7965 208.87 44.5904 210.032 44.5904H213.233V44.5869Z" fill="#1E1E1E"/>
</svg>
</template>
