<template lang="pug">
teleport(to="#modals")
  .simple-subscription-gift-popup(v-if="opened")
    .simple-subscription-gift-popup-dismiss(@click.stop.prevent="close")

    .simple-subscription-gift-popup-content
      .simple-subscription-gift-popup-content-crop(style="background-color: #f3edf7")
        picture(@click="goTo('oferta', 'start')")
          source(
            srcset="~/assets/home/promotions/subscription-gift/2024-07-alfazema/2024-07-alfazema-popup.webp 1x, ~/assets/home/promotions/subscription-gift/2024-07-alfazema/2024-07-alfazema-popup-2x.webp 2x",
            width="480",
            height="600",
            type="image/webp"
          )
          img(
            src="~/assets/home/promotions/subscription-gift/2024-07-alfazema/2024-07-alfazema-popup.png",
            width="480",
            height="600"
          )

      .simple-subscription-gift-popup-dismiss-button(:onClick.stop.prevent="close") X

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import { useAppScroll } from '~/store/appScroll'

import couponService from "~/services/subscription/coupon/couponService"

const giftCoupons = ['alfazema']

const appScroll = useAppScroll()
const route = useRoute()

const opened = ref(false)

const giftCoupon = computed(() => {
  const coupon = route.query.coupon

  if (coupon) {
    if (Array.isArray(coupon)) {
      return coupon.find(c => c != null && giftCoupons.includes(c.toLowerCase()))
    } else {
      return giftCoupons.includes(coupon.toLowerCase()) ? coupon : null
    }
  }

  return null
})

const hasGiftCoupon = computed(() => {
  const coupon = giftCoupon.value

  if (coupon) {
    return giftCoupons.includes(coupon.toLowerCase())
  }

  return false
})

const open = () => {
  opened.value = true
  appScroll.disableScroll()
}

const close = () => {
  opened.value = false
  appScroll.enableScroll()
}

const goTo = (elementId: string, block: ScrollLogicalPosition = 'center') => {
  close()

  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block,
  })
}

var openTimeout: ReturnType<typeof setTimeout>
onMounted(() => {
  if (hasGiftCoupon.value) {
    open()
  }
})

onUnmounted(() => {
  clearTimeout(openTimeout)

  if (opened.value) {
    appScroll.enableScroll()
  }
})
</script>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

$popup-width: 480px
$popup-max-width: 92vw

#modals
  .simple-subscription-gift-popup
    position: fixed
    display: flex
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 9998
    align-items: center
    justify-content: center
    padding: 18px

    .simple-subscription-gift-popup-dismiss
      position: absolute
      top: -50vh
      left: -50vw
      width: 200vw
      height: 200vh
      background-color: rgba(0, 0, 0, 0.25)
      animation: fade-in ease-out .16s forwards

    .simple-subscription-gift-popup-content
      position: relative
      padding: 0
      color: #000
      border-radius: 8px
      text-align: center
      z-index: 9999
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
      opacity: 0
      transform: translateY(32px)
      animation: fade-in ease-out .16s .16s forwards, slide-up ease-out .16s .16s forwards

      @keyframes slide-up
        from
          transform: translateY(32px)

        to
          transform: translateY(0)

      .simple-subscription-gift-popup-content-crop
        position: relative
        width: $popup-width
        max-width: $popup-max-width
        border-radius: 8px
        overflow: hidden
        cursor: pointer

        picture

          img
            width: $popup-width
            height: auto
            vertical-align: middle
            max-width: $popup-max-width

      @include upperBreakpoint(extrasmall)
        max-width: 280px

        .simple-subscription-gift-popup-content-crop
          width: 280px

          picture

            img
              width: 280px

      .simple-subscription-gift-popup-dismiss-button
        position: absolute
        top: -16px
        right: -16px
        width: 32px
        height: 32px
        border-radius: 16px
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        color: #FFF
        line-height: 32px
        font-weight: bold
        text-align: center
        cursor: pointer

        &:hover
          background-color: scale-color($magenta, $lightness: +15%)
</style>
