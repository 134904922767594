<template lang="pug">
.how-it-works
  .content
    h2 Como funciona?

    .list
      .item
        .image
          picture
            source(
              srcset="~/assets/home/sections/how-it-works/cellphone-profile.webp 1x, ~/assets/home/sections/how-it-works/cellphone-profile-2x.webp 2x, ~/assets/home/sections/how-it-works/cellphone-profile-3x.webp 3x, ~/assets/home/sections/how-it-works/cellphone-profile-4x.webp 4x"
              type="image/webp"
            )
            source(
              srcset="~/assets/home/sections/how-it-works/cellphone-profile.png 1x, ~/assets/home/sections/how-it-works/cellphone-profile-2x.png 2x, ~/assets/home/sections/how-it-works/cellphone-profile-3x.png 3x, ~/assets/home/sections/how-it-works/cellphone-profile-4x.png 4x"
            )
            img(
              src="~/assets/home/sections/how-it-works/cellphone-profile.png",
              alt="Mural com depoimentos de milhares de pessoas que já assinam e amam a Box Magenta",
              width="136",
              height="236"
            )
        h3 1. Você preenche seu perfil de beleza
        .description
          | Conta pra gente como você é, tudo o que você gosta e também o que você precisa

      .item
        .image
          picture
            source(
              srcset="~/assets/home/sections/how-it-works/magnifier.webp 1x, ~/assets/home/sections/how-it-works/magnifier-2x.webp 2x, ~/assets/home/sections/how-it-works/magnifier-3x.webp 3x, ~/assets/home/sections/how-it-works/magnifier-4x.webp 4x"
              type="image/webp"
            )
            source(
              srcset="~/assets/home/sections/how-it-works/magnifier.png 1x, ~/assets/home/sections/how-it-works/magnifier-2x.png 2x, ~/assets/home/sections/how-it-works/magnifier-3x.png 3x, ~/assets/home/sections/how-it-works/magnifier-4x.png 4x"
            )
            img(
              src="~/assets/home/sections/how-it-works/magnifier.png",
              alt="Mural com depoimentos de milhares de pessoas que já assinam e amam a Box Magenta",
              width="168",
              height="168"
            )
        h3 2. A gente encontra os melhores produtos pra você
        .description
          | Usamos o seu perfil de beleza pra encontrar os produtos que funcionam e mais
          | combinam com você! Assim você só recebe produtos que realmente vai gostar e conseguir usar

      .item
        .image
          picture
            source(
              srcset="~/assets/home/sections/how-it-works/box.webp 1x, ~/assets/home/sections/how-it-works/box-2x.webp 2x, ~/assets/home/sections/how-it-works/box-3x.webp 3x, ~/assets/home/sections/how-it-works/box-4x.webp 4x"
              type="image/webp"
            )
            source(
              srcset="~/assets/home/sections/how-it-works/box.png 1x, ~/assets/home/sections/how-it-works/box-2x.png 2x, ~/assets/home/sections/how-it-works/box-3x.png 3x, ~/assets/home/sections/how-it-works/box-4x.png 4x"
            )
            img(
              src="~/assets/home/sections/how-it-works/box.png",
              alt="Mural com depoimentos de milhares de pessoas que já assinam e amam a Box Magenta",
              width="174",
              height="214"
            )
        h3 3. Você recebe uma box exclusiva e incrível
        .description
          | Todo mês, 5 novos produtos em tamanho real e perfeitos pra você vão chegar na sua casa!
</template>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.how-it-works
  background-color: #3A3A3A
  color: white

  > .content
    position: relative
    max-width: 1232px
    margin: 0 auto

    > h2
      margin: 0
      padding: 32px 16px 0
      text-align: center
      font-size: 32px
      color: white

    > .list
      position: relative
      display: flex
      gap: 32px
      justify-content: space-between
      width: 100%
      overflow-x: auto
      margin: 16px 0 0
      padding: 0 16px 16px
      scroll-snap-type: x mandatory

      > .item
        flex-shrink: 0
        min-width: 240px
        width: calc(100vw - 32px)
        padding: 8px
        border-radius: 4px
        text-align: center
        scroll-snap-align: center
        scroll-snap-stop: always

        @include breakpoint(mobileonly)
          &
            flex-shrink: 1
            width: 360px

        > h3
          color: white

        > .image
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          height: 220px
          overflow: visible

        > .description
          font-size: 14px

</style>
