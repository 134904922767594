<template lang="pug">
.headline

  .background

    .image

      .photo

        img.fifty-off(src="~/assets/home/promotions/2024-11-black-friday/headline-50off.svg", type="image/svg+xml")

        picture
          source(
            srcset="~/assets/home/promotions/2024-11-black-friday/boxes-headline.webp 1x, ~/assets/home/promotions/2024-11-black-friday/boxes-headline-2x.webp 2x",
            type="image/webp"
          )
          source(
            srcset="~/assets/home/promotions/2024-11-black-friday/boxes-headline.png 1x, ~/assets/home/promotions/2024-11-black-friday/boxes-headline-2x.png 2x",
            type="image/png"
          )
          img.boxes(
            src="~/assets/home/promotions/2024-11-black-friday/boxes-headline.png"
          )

  .content
    .text

      .logo
        MagentaFridayLogo

        .period
          | de 11/11 a 22/11

      .price
        div
         | sua 1ª box por

        div
          | R$149,90
          .strike

        div
          span R$&nbsp;
          span 99,90
          span *

        img.menor-preco(src="~/assets/home/promotions/2024-11-black-friday/menor-preco.svg", type="image/svg+xml")

      .notice
        | * valor válido para o 1º mês de assinatura no plano anual

    a.cta(href="/oferta", @click="goToOffer") Quero aproveitar

</template>
<script setup lang="ts">
import MagentaFridayLogo from "./MagentaFridayLogo.vue"

const goToOffer = (e: Event) => {
  e.preventDefault()

  document.getElementById("oferta")?.scrollIntoView({
    behavior: "smooth",
  })
}
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

$headline-height: 650px
$header-height: 150px
$background-height: $headline-height + $header-height

.headline
  position: relative

  .background
    $background-height: $headline-height + $header-height
    position: absolute
    top: -$header-height
    left: 0
    width: 100%
    height: $background-height
    background-color: $magenta
    z-index: -1
    overflow: hidden

    .image
      display: block
      position: relative
      width: 100%
      max-width: 1440px
      height: $headline-height
      margin: 0 auto
      flex-direction: column
      align-items: center
      overflow: visible

      &::before
        content: ""
        position: absolute
        top: 0
        left: 100%
        right: -527px
        width: 100vw
        height: $background-height
        background-color: $beige
        z-index: -1

      &::after
        content: ""
        position: absolute
        top: -132px
        right: -555px
        width: 1265px
        height: 1265px
        border-radius: 50%
        background-color: $beige
        z-index: -1

      .photo
        position: absolute
        top: 100px
        right: -7px

        img.boxes
          display: inline-block
          width: 737px
          height: auto

        img.fifty-off
          display: inline-block
          position: absolute
          top: 25px
          left: 406px

  .content
    position: relative
    width: 100%
    max-width: 1440px
    margin: 0 auto
    height: $headline-height
    overflow: hidden

    .text
      color: #1e1e1e

      .logo
        position: absolute
        top: 92px
        left: 35px

        svg
          width: 606px
          height: auto

        .period
          position: absolute
          top: 130px
          left: 5px
          font-family: "Spartan", sans-serif
          font-size: 30px
          font-weight: normal
          letter-spacing: -1.2px

      .price
        position: absolute
        top: 320px
        left: 168px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 8px
        font-family: "Spartan", sans-serif

        > :nth-child(1)
          font-size: 40px
          font-weight: bold
          letter-spacing: -1.2px

        > :nth-child(2)
          position: relative
          font-size: 46px

          .strike
            display: block
            position: absolute
            left: 50%
            top: 50%
            width: 105%
            height: 3px
            background-color: #1e1e1e
            transform: translate(-50%, -50%) rotate(-3deg)

        > :nth-child(3)
          position: relative
          color: $beige
          font-weight: bold
          font-size: 56px
          text-shadow: 0 0 8px $magenta

          > :nth-child(2)
            font-size: 94px

          > :nth-child(3)
            position: absolute
            top: 12px
            font-style: super

        img.menor-preco
          position: absolute
          top: 0
          left: -120px
          width: 136px
          height: auto

      .notice
        position: absolute
        left: 32px
        top: 598px
        font-size: 20px
        color: $beige

    .cta
      position: absolute
      display: inline-flex
      top: 492px
      right: 68px
      padding: 24px 38px
      justify-content: center
      align-items: center
      border-radius: 120px
      background: #1E1E1E
      color: $beige
      text-align: center
      font-family: "Spartan", sans-serif
      font-size: 40px
      font-weight: 600
      letter-spacing: -2px


@mixin scaleDesktop($scale)
  .headline
    .background
      $background-height: $scale * $headline-height + $header-height
      height: $background-height

      .image
        &::before
          right: $scale * -527px
          height: $background-height

        &::after
          top: $scale * -132px
          right: $scale * -555px
          width: $scale * 1265px
          height: $scale * 1265px


        .photo
          right: -40px

          img.boxes
            width: ($scale * 737px)

          img.fifty-off
            left: $scale * 406px
            width: ($scale * 261px)

    .content
      height: $scale * $headline-height

      .text

        .logo
          top: $scale * 92px

          svg
            width: $scale * 606px

          .period
            top: $scale * 130px
            font-size: $scale * 30px

        .price
          top: $scale * 320px
          left: $scale * 168px

          > :nth-child(1)
            font-size: $scale * 40px

          > :nth-child(2)
            font-size: $scale * 46px

          > :nth-child(3)
            font-size: $scale * 56px

            > :nth-child(2)
              font-size: $scale * 94px

          img.menor-preco
            left: $scale * -120px
            width: $scale * 136px

        .notice
          top: $scale * 598px
          left: $scale * 32px
          font-size: 14px

      .cta
        top: $scale * 492px
        right: $scale * 68px
        font-size: $scale * 40px
        padding: $scale * 24px $scale * 38px

@media (max-width: 1400px)
  @include scaleDesktop(0.9)

@media (max-width: 1280px)
  @include scaleDesktop(0.85)

@include tablet
  @include scaleDesktop(0.7)

@include phablet
  .headline

    .background
      top: -100px
      height: 624px

      .image
        height: 523px

        &::before
          content: ""
          position: absolute
          top: 0
          left: 100%
          right: -527px
          width: 100vw
          background-color: $beige
          z-index: -1

        &::after
          top: 336px
          left: 50%
          width: 821px
          height: 627px
          border-radius: 50%
          transform: translateX(-50%)

        .photo
          top: 288px
          right: 50%
          transform: translateX(50%)

          img.boxes
            width: 339px
            height: auto

          img.fifty-off
            display: none

    .content
      height: 523px

      .text

        .logo
          position: absolute
          top: 8px
          left: 50%
          transform: translateX(-50%)

          svg
            width: 248px
            height: auto

          .period
            top: 53px
            left: 2px
            color: $beige
            font-size: 14px

        .price
          top: 100px
          left: 50%
          transform: translateX(-50%)

          > :nth-child(1)
            font-size: 18px

          > :nth-child(2)
            font-size: 20px

            .strike
              height: 2px

          > :nth-child(3)

            > :nth-child(1)
              font-size: 28px

            > :nth-child(2)
              font-size: 48px

            > :nth-child(3)
              top: 6px
              font-size: 28px


          img.menor-preco
            left: -48px
            width: 74px

        .notice
          top: 216px
          left: 50%
          transform: translateX(-50%)
          font-size: 10px
          white-space: nowrap
          color: #1e1e1e

      .cta
        font-size: 20px
        top: 430px
        left: 50%
        transform: translateX(-50%)
        width: 90vw
        padding: 14px 84px
        white-space: nowrap

</style>
<style lang="sass">
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

@include phablet
  .headline
    .content
      .text
        .logo
          path
            fill: $beige
</style>
