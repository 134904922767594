<template lang="pug">
teleport(to="#modals")
  .subscription-gift-popup(v-if="opened")
    .subscription-gift-popup-dismiss(@click.stop.prevent="toggleOpened")

    .subscription-gift-popup-content
      .subscription-gift-popup-content-crop(style="background-color: rgb(220, 216, 189);")
        Carousel
          Slide(key="01")
            picture(@click="goTo('oferta', 'start')")
              source(
                srcset="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-01.jpg 1x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-01-2x.jpg 2x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-01-3x.jpg 3x",
                width="339",
                height="520"
              )
              img(
                src="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-01.jpg",
                width="339",
                height="520"
              )

          Slide(key="02")
            picture(@click="goTo('plano-anual')")
              source(
                srcset="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-02.jpg 1x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-02-2x.jpg 2x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-02-3x.jpg 3x",
                width="339",
                height="520"
              )
              img(
                src="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-02.jpg",
                width="339",
                height="520"
              )

            img(
              v-if="showSecondSlideUnavailable",
              style="position: absolute; top: 88px; left: 86px; width: 172px; height: 172px;"
              src="~/assets/home/promotions/subscription-gift/unavailable-seal.svg"
            )

          Slide(key="03")
            picture(@click="goTo('plano-mensal')")
              source(
                srcset="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-03.jpg 1x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-03-2x.jpg 2x, ~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-03-3x.jpg 3x",
                width="339",
                height="520"
              )
              img(
                src="~/assets/home/promotions/subscription-gift/2024-02-loccitane-au-bresil/2024-02-loccitane-au-bresil-03.jpg",
                width="339",
                height="520"
              )

            img(
              v-if="showThirdSlideUnavailable",
              style="position: absolute; top: 122px; left: 12px; width: 172px; height: 172px;"
              src="~/assets/home/promotions/subscription-gift/unavailable-seal.svg"
            )

          template(v-slot:addons)
            Navigation

      .subscription-gift-popup-dismiss-button(:onClick.stop.prevent="toggleOpened") X

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import { useAppScroll } from '~/store/appScroll'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
import type { SubscriptionGiftAvailabilityResponse } from '~/services/subscription/promotion/promotionService'

const props = defineProps({
  subscriptionGiftPromotions: {
    type: Object as PropType<SubscriptionGiftAvailabilityResponse[]>,
    required: true,
    default: []
  },
})

const appScroll = useAppScroll()

const opened = ref(false)

const toggleOpened = () => {
  opened.value = !opened.value

  if (opened.value) {
    appScroll.disableScroll()
  } else {
    appScroll.enableScroll()
  }
}

const showSecondSlideUnavailable = computed(() => {
  return false
  // return props.subscriptionGiftPromotions.find((promotion) => promotion.ruleId == "2023-11-black-friday-02") == undefined
})

const showThirdSlideUnavailable = computed(() => {
  return false
  // return props.subscriptionGiftPromotions.find((promotion) => promotion.ruleId == "2023-11-black-friday-01") == undefined
})

const goTo = (elementId: string, block: ScrollLogicalPosition = 'center') => {
  toggleOpened()

  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block,
  })
}

var openTimeout: ReturnType<typeof setTimeout>
onMounted(() => {
  openTimeout = setTimeout(toggleOpened, 500)
})

onUnmounted(() => {
  clearTimeout(openTimeout)

  if (opened.value) {
    appScroll.enableScroll()
  }
})
</script>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

#modals
  .subscription-gift-popup
    position: fixed
    display: flex
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 9998
    align-items: center
    justify-content: center
    padding: 18px

    .subscription-gift-popup-dismiss
      position: absolute
      top: -50vh
      left: -50vw
      width: 200vw
      height: 200vh
      background-color: rgba(0, 0, 0, 0.25)
      animation: fade-in ease-out .16s forwards

    .subscription-gift-popup-content
      position: relative
      padding: 0
      max-width: 360px
      max-height: 492px
      color: #000
      border-radius: 8px
      text-align: center
      z-index: 9999
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
      opacity: 0
      transform: translateY(32px)
      animation: fade-in ease-out .16s .16s forwards, slide-up ease-out .16s .16s forwards

      .carousel__viewport
        border-radius: 8px

        .carousel__track
          margin: 0

      .carousel__prev, .carousel__next
        box-sizing: content-box
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        border-radius: 50%
        margin: 0
        color: $white

      .carousel__prev
        transform: translateX(-60%)

      .carousel__next
        transform: translateX(60%)

      .carousel__prev--disabled, .carousel__next--disabled
        display: none

      .carousel__slide--visible
        transform: rotateY(0)

      @keyframes slide-up
        from
          transform: translateY(32px)

        to
          transform: translateY(0)

      .subscription-gift-popup-content-crop
        position: relative
        width: 339px
        height: 520px
        border-radius: 8px
        cursor: pointer

        picture
          display: block
          height: 520px

          img
            width: 339px
            height: auto

      @include upperBreakpoint(extrasmall)
        max-width: 280px

        .subscription-gift-popup-content-crop
          width: 280px
          height: 429px

          picture
            height: 429px

            img
              width: 280px

      .subscription-gift-popup-dismiss-button
        position: absolute
        top: -16px
        right: -16px
        width: 32px
        height: 32px
        border-radius: 16px
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        color: #FFF
        line-height: 32px
        font-weight: bold
        text-align: center
        cursor: pointer

        &:hover
          background-color: scale-color($magenta, $lightness: +15%)
</style>
