<template lang="pug">
.headline
  .headline-text
    h1 Descubra seus novos produtos de beleza preferidos

    p Receba produtos de cabelo, maquiagem e skincare em tamanho real escolhidos a dedo pra você

  .headline-cta
    a.headline-cta--button(href="/oferta", @click="goToOffer") Quero assinar

  picture
    source(
      media="(max-width: 767px)",
      srcset="~/assets/home/sections/headline/your-box-mobile.jpg 1x, ~/assets/home/sections/headline/your-box-mobile-2x.jpg 2x",
      width="768",
      height="722",
    )
    source(
      media="(min-width: 768px)",
      srcset="~/assets/home/sections/headline/your-box.jpg 1x, ~/assets/home/sections/headline/your-box-2x.jpg 2x",
      width="1200",
      height="585",
    )
    img(
      src="~/assets/home/sections/headline/your-box.jpg",
      alt="A sua Box Magenta vem com produtos selecionados a dedo para você. Produtos para o seu tipo de cabelo, maquiagens para o seu tom de pele e skincare focada no que você precisa, tudo com os seus aromas preferidos. São 5 produtos full-size, livres de crueldade animal, com novas marcas para você conhecer. Todo mês na sua casa!",
      width="1200",
      height="585",
    )

</template>
<script setup lang="ts">
const goToOffer = (e: Event) => {
  e.preventDefault()

  document.getElementById("oferta")?.scrollIntoView({
    behavior: "smooth",
  })
}
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"

.headline
  display: flex
  position: relative
  width: 100%
  max-width: 1232px
  margin: 0 auto
  flex-direction: column
  align-items: center
  overflow: hidden
  padding: 0 16px

  .headline-text
    text-align: center
    margin-bottom: 32px
    max-width: 900px

    h1
      color: #000
      line-height: 1.1
      font-weight: 700

    p
      margin: 0

    b
      color: #ef368b
      font-weight: 800

  .headline-cta
    margin-bottom: 8px
    padding: 0 16px
    width: 100%
    max-width: 400px

    .headline-cta--button
      position: relative
      display: block
      padding: 16px 32px
      width: 100%
      font-size: 20px
      font-weight: 800
      background-color: #ef368b
      border-radius: 32px
      color: #fff
      text-align: center
      box-shadow: 0 4px 24px 0 rgba(238, 61, 138, 0.3)

      &:hover
        background-color: scale-color(#ef368b, $lightness: +15%)

    .headline-cta--subscript
      font-size: 12px

  img
    width: calc(100% + 28px)
    height: auto
    object-fit: contain
    aspect-ratio: 768 / 722
    margin: 32px 0
    transform: translateX(-12px)

  @include breakpoint(tablet)
    .headline-text
      margin-bottom: 48px

      h1
        font-size: 48px

      p
        font-size: 20px

    .headline-cta
      margin-bottom: 32px
      width: initial

      .headline-cta--button
        width: initial

    img
      width: 1200px
      max-width: 100%
      transform: none
      aspect-ratio: 1200 / 585
      margin: 0 0  32px 0
</style>
